































































































































































































































































































































































import {Component, Prop, VModel, Watch} from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {mixins} from 'vue-class-component';
import {namespace} from 'vuex-class';
import {validationMixin} from 'vuelidate';
import {
  email,
  maxLength,
  minLength,
  numeric,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import User from '@/models/User';
import UserRole from '@/models/user-attributes/UserRole';
import Gender from '@/models/user-attributes/Gender';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import {EmploymentTypes} from '@/misc/user-attributes/EmploymentTypes';
import PaymentType from '@/models/user-attributes/PaymentType';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import Denomination from '@/models/user-attributes/Denomination';

import {VersionControl} from '@/misc/VersionControl';
import {userStoreActions, userStoreGetter, userStoreMutations} from '@/stores/user.store';
import {authStoreGetter, authStoreMutations} from '@/stores/auth.store';

const UserStore = namespace('user');
const AuthStore = namespace('auth');

/**
 * Component to create or edit a user entity
 */
@Component({
  mixins: [validationMixin],
  validations: {
    userCopy: {
      role: {
        required,
      },
      email: {
        required: requiredIf((vm) => {
          // required if at create the hasNoEmail prop is false
          const create = !vm.id && vm.userHasNoEmail === false;
          // required if at edit no personnel number is set
          const edit = vm.id && !vm.personnelNumber;
          // required if role is not employee
          return vm.role.name !== 'employee' || create || edit;
        }),
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      userName: {
        required,
      },
      address: {
        houseNo: {maxLength: maxLength(15)},
        postalCode: {numeric, maxLength: maxLength(5)},
      },
      phone: {
        required,
        numeric,
        minLength: minLength(3),
      },
      externCompanyName: {
        required: requiredIf((vm) => vm.isExtern),
      },
    },
  },
  components: {
    MenuWithPicker: () => import(
        '@/components/shared/MenuWithPicker.component.vue'),
    RJTextField: () => import(
        '@/components/shared/custom-vuetify/RJTextField.vue'),
    RJSelect: () => import(
        '@/components/shared/custom-vuetify/RJSelect.vue'),
    SideCardComponent: () => import(
        '@/components/shared/SideCard.component.vue'),
    WorkingTimeComponent: () => import(
        '@/components/shared/WorkingTime.component.vue'),
  },
})
export default class UserManageContentComponent extends mixins(ErrorMessageHandlerMixin) {

  @Prop({default: () => new User()})
  public user!: User;

  @Prop({default: () => true})
  public gotoUsersOverview!: boolean;

  @Prop({default: () => true})
  public isEditable?: boolean;

  @VModel({default: () => false})
  public checkChanges?: boolean;

  public userCopy: User = new User();


  public submitted: boolean = false;
  public personalNumberInUse: boolean = false;
  public role: UserRole | null = null;
  public oneTimePassword: string = '';
  public hasSameEmail: boolean = false;

  // enable this if customer wants extra attributes
  public personalInfoActive: boolean = false;

  private resetPassword: boolean = false;


  @UserStore.Action(userStoreActions.CREATE_USER_ACTION)
  private createUserAction!: (user: User) => Promise<User>;
  @UserStore.Action(userStoreActions.EDIT_USER_ACTION)
  private editUserAction!: (user: User) => Promise<User>;
  @UserStore.Mutation(userStoreMutations.STORE_ACTIVE_USER)
  private activeUserMutation!: (user: User) => any;
  @UserStore.Action(userStoreActions.LOAD_USER_ROLES_ACTION)
  private loadRolesAction!: (payload: { tenantId: string, relations?: string[] }) => void;
  @UserStore.Action(userStoreActions.SET_USER_STATUS_ACTION)
  private setStatusAction!: () => void;
  @AuthStore.Mutation(authStoreMutations.SAVE_USER)
  private saveUserMutation!: (user: User) => void;
  @AuthStore.Getter(authStoreGetter.USER)
  private _user!: User;


  @UserStore.Getter(userStoreGetter.ROLES)
  private _roles!: UserRole[];

  /**
   * get UserRoles with translated names
   */
  get roles(): Array<{ text: any, value: UserRole }> {
    return this._roles.filter((item) => !item.superAdmin).map((entry) => {
      return {text: entry.name, value: entry};
    });
  }

  @UserStore.Getter('employmentTypes')
  private _employmentTypes!: EmploymentType[];

  get employmentTypes(): Array<{ text: any, value: EmploymentType }> {
    return this._employmentTypes.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.EMPLOYMENT_TYPE.${entry.name.toUpperCase()}`) ?
          this.$t(`USER_DETAIL.EMPLOYMENT_TYPE.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('denominations')
  private _denominations!: Denomination[];

  get denominations(): Array<{ text: any, value: Denomination }> {
    return this._denominations.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.DENOMINATION.${entry.name.toUpperCase()}`) ?
          this.$t(`USER_DETAIL.DENOMINATION.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('paymentTypes')
  private _paymentTypes!: PaymentType[];

  get paymentTypes(): Array<{ text: any, value: PaymentType }> {
    return this._paymentTypes.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.PAYMENT_TYPE.${entry.name.toUpperCase()}`) ?
          this.$t(`USER_DETAIL.PAYMENT_TYPE.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('gender')
  private _gender!: Gender[];

  get gender(): Array<{ text: any, value: Gender }> {
    return this._gender.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.GENDER.${entry.name.toUpperCase()}`) ?
          this.$t(`USER_DETAIL.GENDER.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  @UserStore.Getter('billingDeliveries')
  private _billingDeliveries!: BillingDelivery[];

  get billingDeliveries(): Array<{ text: any, value: BillingDelivery }> {
    return this._billingDeliveries.map((entry) => {
      const elmText = this.$t(`USER_DETAIL.BILLING_DELIVERY.${entry.name.toUpperCase()}`) ?
          this.$t(`USER_DETAIL.BILLING_DELIVERY.${entry.name.toUpperCase()}`) : entry.name;
      return {text: elmText, value: entry};
    });
  }

  get paymentIntervals(): Array<{ text: any, value: number }> {
    return [
      {text: this.$t('USER_DETAIL.PAYMENT_INTERVAL.MONTHLY'), value: 1},
      {text: this.$t('USER_DETAIL.PAYMENT_INTERVAL.QUARTER'), value: 3},
    ];
  }

  get residence(): Array<{ text: any, value: boolean }> {
    return [
      {text: this.$t('USER_DETAIL.NATIONALITY.EU_CITIZEN'), value: true},
      {text: this.$t('USER_DETAIL.NATIONALITY.NO_EU_CITIZEN'), value: false},
    ];
  }

  get pensionInsurances(): Array<{ text: any, value: boolean }> {
    return [
      {text: this.$t('USER_DETAIL.PENSION_INSURANCE.FREE'), value: true},
      {text: this.$t('USER_DETAIL.PENSION_INSURANCE.COMPULSORY'), value: false},
    ];
  }

  get roleErrorMessage(): string {
    if (this.submitted && !this.roles.map((role) => role.value.name).includes((this.role as UserRole).name)) {
      return this.$t('ERROR_MESSAGES.REQUIRED').toString();
    }
    return '';
  }

  get isMiniJobber() {
    return this.userCopy.employmentType && this.userCopy.employmentType.name === EmploymentTypes.miniJob;
  }

  @Watch('user', {immediate: true})
  public onUserChange() {
    this.submitted = false;
    this.userCopy = User.parseFromObject(this.user);
    this.role = UserRole.parseFromObject(this.userCopy.role as UserRole);
    this.$v.$reset();
  }

  @Watch('checkChanges')
  public onCloseSideCard() {
    if (this.checkChanges) {
      VersionControl.compare(this.user, this.userCopy) ? this.$emit('exit') : this.$emit('warn-dialog');
    }
  }

  /**
   * Tells the parent to close the modal window
   */
  public cancelButtonClicked() {
    this.checkChanges = true;
    this.$emit('cancel');
  }

  /**
   * Event handler that saves the edition or the creation of user object
   */

  public onResetPassword() {
    this.resetPassword = !this.resetPassword;
  }

  /**
   * email and hasNoEmail shouldn't coexist, this prevents this
   */
  public onNoEmailCheckboxClick() {
    if (this.userCopy.userHasNoEmail) {
      this.userCopy.email = '';
    }
  }

  public async onSubmit(event: Event) {
    event.preventDefault();
    this.submitted = true;
    this.$v.userCopy!.$touch();
    if (this.$v.userCopy!.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of a css selector of a vuetify property is not always safe
      requestAnimationFrame(async () => await this.$vuetify.goTo('.error--text', {offset: 50}));
      this.$notifyErrorSimplified('GENERAL.MISSING_DATA');
    } else {
      this.userCopy.roleId = this.role?.id;
      delete this.userCopy.role;
      const isEdit = !!this.userCopy!.id;
      if (this.userCopy.personnelNumber === '') {
        this.userCopy.personnelNumber = null;
      }
      try {
        if (isEdit) {
          delete this.userCopy.tenant;
          const res = await this.editUserAction(this.userCopy);
          if (res.id === this._user.id) {
            await this.saveUserMutation(res);
          }
          this.$emit('exitModal');
        } else {
          this.userCopy!.tenantId = this.$route.params.tenantId;
          const createdUser = await this.createUserAction(this.userCopy);
          this.userCopy.id = createdUser.id;

          // direct to user overview, after user was created
          if (this.gotoUsersOverview) {
            await this.$router.push({
              name: 'usersOverview',
              params: {
                tenantId: this.$route.params.tenantId,
              },
            });
          }
        }

        this.$emit('exitModal', this.userCopy);
        if (isEdit) {
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_EDIT.SUCCESS');
        } else {
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_CREATE.SUCCESS');
        }
      } catch (e) {
        this.userCopy.oneTimePassword = '';
        this.userCopy.role = this.role!;
        if (e.status === 412) {
          this.personalNumberInUse = true;
          this.$notifyErrorSimplified(`USER_MANAGE.NOTIFICATIONS.412`);
        } else if (!isEdit && e.status === 409) {
          this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.CREATED.409');
          this.hasSameEmail = true;
        } else if (this.$te(`USER_MANAGE.NOTIFICATIONS.${e.status}`)) {
          this.$notifyErrorSimplified(`USER_MANAGE.NOTIFICATIONS.${e.status}`);
        } else {
          if (isEdit) {
            this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_EDIT.ERROR');
          } else {
            this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_CREATE.ERROR');
          }
        }
      }
    }
  }

  /**
   * loads the roles when the popup is opened
   */
  public mounted() {
    this.createPassword();
    try {
      this.loadRolesAction({tenantId: this.$route.params.tenantId});

      this.role = UserRole.parseFromObject(this.userCopy.role as UserRole);
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  /**
   * This attribute needs to be inverted for frontend
   * @private
   */

  private createPassword() {
    this.oneTimePassword = Math.random().toString(36).substr(2, 8);
  }

  private setUserRole(role: UserRole) {
    this.userCopy.role = role;
  }

  /**
   * Status button event handler
   */
  private async onStatusButtonClick() {
    this.userCopy!.active = !this.userCopy!.active;
    try {
      await this.editUserAction(this.userCopy);
      if (this.userCopy!.active) {
        this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_ACTIVATE');
      } else {
        this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DEACTIVATE');
      }
      this.$emit('exitModal', this.userCopy);
    } catch (e) {
      if (this.$te(`USER_MANAGE.NOTIFICATIONS.${e.status}`)) {
        this.$notifyErrorSimplified(`USER_MANAGE.NOTIFICATIONS.${e.status}`);
      } else {
        this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_EDIT.ERROR');
      }
    }
  }

  /**
   * Delete button event handler
   */
  private onDeleteButtonClick() {
    console.log('this function onDeleteButtonClick is not implemented yet');
  }
}
